const initSwiper = () => {
  // eslint-disable-next-line no-undef
  const mySwiper = new Swiper('.swiper', {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 500000,
    },
    loop: true,
    slideToClickedSlide: true,
    on: {
      slideChangeTransitionEnd() {
        const active = Number(document.querySelector('.swiper-slide-active')?.dataset?.swiperSlideIndex);
        const headInfo = document.querySelector('.container .head-info');
        const headTitle = document.querySelector('.container .head-title');
        if (active === 0) {
          headTitle.innerHTML = 'MIC Grab';
          headInfo.innerHTML = 'MIC GrabEnioy Mic game<br class="d-none d-lg-block">and invite Friends to join it';
        }
        if (active === 1) {
          headTitle.innerHTML = 'Competition';
          headInfo.innerHTML = 'Come and fight for your honor';
        }
        if (active === 2) {
          headTitle.innerHTML = 'Private Chat';
          headInfo.innerHTML = 'Conversation with friends';
        }
      },
    }
  });
  mySwiper;
};
export default initSwiper;
